@import "../../assets/styles/Variables.scss";

// iframe {
//   display: none;
// }

.measurements-page {
  .page-inner {
    .common-tab {
      .tab-contant {
        max-width: 750px;
        margin: 0px auto;
      }
    }
  }
}

.hint-text {
  p {
    color: $white;
    font-size: 14px;
    line-height: 18px;
    a {
      color: $white;
    }
  }
}

//sun-chart-box
.sun-chart-box {
  text-align: center;
  width: 600px;
  margin: 0px auto;
  position: relative;
  svg {
    width: 600px;
  }
  .errow-text {
    position: absolute;
    bottom: 30px;
    .time {
      font-size: 14px;
      color: $white;
      font-weight: 400;
      display: block;
    }
    .time-status {
      font-size: 14px;
      color: $white;
      font-weight: 400;
      opacity: 0.5;
      margin: 0px;
    }

    &.up-errow-text {
      left: 0px;
    }
    &.down-errow-text {
      right: 0px;
    }
  }

  .time-circle-text {
    position: absolute;
    top: 160px;
    .time {
      font-size: 14px;
      color: $white;
      font-weight: 400;
      display: block;
    }
    .time-status {
      font-size: 14px;
      color: $white;
      font-weight: 400;
      opacity: 0.5;
      margin: 0px;
    }
    &.time-circle-text-left {
      left: -105px;
      text-align: right;
    }
    &.time-circle-text-right {
      right: -105px;
      text-align: left;
    }
  }
}

//status-bars-section
.status-bars-section {
  .status-bar-box {
    margin-bottom: 20px;
    .bar-top {
      margin-bottom: 5px;
      ion-label {
        color: $white;
        font-size: 12px;
        line-height: 16px;
        display: inline-block;
        width: 33%;
        &.grey-text {
          color: #707070;
        }
        &.green-text {
          color: #73c700;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          text-align: right;
        }
      }
    }
    .bar-mid {
      position: relative;
      ion-progress-bar {
        height: 30px;
        --background: #353535;
        border-radius: 4px;
      }
      .status {
        font-size: 12px;
        line-height: 16px;
        color: $white;
        position: absolute;
        top: 6px;
        left: 50%;
      }
    }
    &.Filiggrin-bar {
      .bar-mid {
        ion-progress-bar {
          --progress-background: #ff6434;
        }
      }
    }
    &.Melatonin-bar {
      .bar-mid {
        ion-progress-bar {
          --progress-background: #f77c01;
        }
      }
    }
    &.vitamin-bar {
      .bar-mid {
        ion-progress-bar {
          --progress-background: #17b9ff;
        }
      }
    }
    &.Trans-bar {
      .bar-mid {
        ion-progress-bar {
          --progress-background: #655bed;
        }
      }
    }
  }
}
