@import "../../assets/styles/Variables.scss";

ion-header {
  border-bottom: 1px solid rgba($white, 0.07);
  &:after {
    background-image: none !important;
  }
  ion-toolbar {
    --background: #{$baseColor};
  }
  .header-left {
    display: flex;
    align-items: center;
    ion-buttons {
      ion-menu-button {
        --padding-bottom: 0px;
        --padding-end: 0px;
        --padding-start: 0px;
        // --padding-top:0px:
        --color: #{$white};
        opacity: 0.9;
      }
    }
    ion-title {
      color: #fff;
      opacity: 0.9;
      padding: 0px;
    }
  }
  .header-right {
    text-align: right;
    ion-button {
      &.notification-button{
        position: relative;
        span{
          position: absolute;
          top: 2px;
          right: -10px;
          width: 18px;
          height: 18px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $danger;
          color: $white;
          font-size: 8px;
          line-height: 10px;
        }
      }
    }
  }
}
